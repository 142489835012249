/* Parent wrapper to hold content and images side by side */
.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 100%;
  padding-left: 110px;
  position: relative;
  font-family: Calibri, sans-serif;
  
}

/* Service List */
.serviceList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: relative;
  
  /* The static gray vertical line on the left */
  /* Add a vertical line to the left side of the list */
  &::before {
    content: '';
    position: absolute;
    left: -40px; /* Adjust the distance from the list */
    top: 0;
    bottom: 0;
    width: 2.5px;
    background-color: #ccc; /* Gray color for the line */
  }
}

/* Each Service Card */
.serviceCard {
  position: relative; /* Position relative to allow the blue highlight */
  margin-bottom: 20px;
  padding: 40px;
  width: 60%;
  border-radius: 8px;
  transition: background-color 0.3s ease, font-weight 0.3s ease;
}

/* Highlight the serviceCard when it becomes active */
.serviceCard.active {
 
  /* Highlight the section of the vertical line next to the active card */
  &::before {
    content: '';
    position: absolute;
    left: -40px; /* Align with the vertical line */
    top: 50%;
   
    width: 2.5px;
    height: 50px; /* Blue highlight height for active item */
    background-color: #007bff; /* Blue color */
    z-index: 1; /* Ensure the highlight is above the gray line */
  }
}

.serviceCard.active .readMore {
  animation: fadeDown 0.6s ease-in-out;
}

/* Container for images */
.imageContainer {
  right: 100px;
  position: sticky;
  top: 200px;
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

/* Styling for individual images */
.imageContainer img {
  width: 100%;
  height: 300px;
  border-radius: 8px;
  transition: filter 0.5s ease;
}

/* Blur effect for image */
.blur {
  opacity: 0.2;
  transition: filter 0.5s ease;
}

.heading {
  padding: 20px;
  font-size: 42px;
  font-weight: 600;
  color: #121212; /* Darker color for headings */
  margin-bottom: 30px;
  text-align: center;
}

.contentHeading {
  font-size: 26px;
  font-weight: 600;
}

.contentWrapper {
  font-size: 19px;
 
  color: #666666; /* Lighter color for text */
  line-height: 1.4;
  
}

@keyframes fadeDown {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(25px);
  }
}

/* Initially hide the "Read More" element */
.readMore {
  color: #007bff; /* Bright blue for the link */
  font-size: x-large;
  font-weight: 600;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  transition: opacity 0.6s ease, transform 0.6s ease, color 0.3s ease; /* Smooth zoom-in and color change */
  opacity: 0; /* Hidden by default */
  transform: translateY(25px); /* Positioned slightly below */
}

/* Arrow mark using the pseudo-element */
.readMore::after {
  content: "→"; /* Unicode arrow symbol */
  margin-left: 5px; /* Space between text and arrow */
  transition: transform 0.3s ease; /* Smooth transition for hover */
  opacity: 1;
}

/* Fade down and make visible when the element is active */
.readMore.visible {
  opacity: 1; /* Now visible */
  transform: translateY(25px); /* Move to its normal position */
}

/* Keep smooth transition on hover */
.readMore:hover {
  opacity: 1;
  color: #0955a7; /* Change color on hover (optional) */
}

.readMore:hover::after {
  opacity: 1;
  transform: translateX(5px); /* Slightly move the arrow on hover */
}

/* Click effect */
.readMore:active {
  opacity: 1;
}

@media (max-width: 800px) {
  .wrapper {
    flex-direction: column;
  }
  .imageContainer {
    position: static;
    width: 100%;
    margin-top: 20px;
  }
}
