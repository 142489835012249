.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between sections */
  padding: 30px;
}

.heading1 {
  font-size: 32px;
  color: #201f1f;
  font-weight: 600;
}

.heading2 {
  font-size: 26px;
  color: #3a3a3a;
  font-weight: 600;
}

.flexContainer {
  width: 100%;
  display: flex; /* Create a flex container */
  justify-content: center;
  gap: 20px; /* Space between the elements and details */
}

.container {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between key elements */
  width: 35%;
}

.element {
  background-color: #d3d3d3;
  border-radius: 8px;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 320px;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.element:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.even {
  margin-left: 200px; /* Adjust for zig-zag effect */
}

.odd {
  margin-left: 0; /* Reset margin for odd elements */
}

.title {
  flex-grow: 1;
  font-size: 20px;
  font-weight: bold;
  color: #1f0699;
  text-align: left;
}

.arrow {
  font-size: 18px;
  color: rgb(4, 20, 135);
  transition: transform 0.3s ease;
}

.element:hover .arrow {
  transform: translateX(10px); /* Move arrow slightly to the right on hover */
}

.detailsContainer {
  margin-top: 0; /* Remove top margin */
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  max-width: 400px;
}

.detailsContainer h2 {
  font-size: 24px;
  color: #1f0699;
}

.detailsContainer p {
  font-size: 16px;
  color: #333;
}

.subtitle {
  font-size: 24px;
  color: #333;
}

/* Updated Swiper and Pagination CSS */

.mySwiper {
  width: 100%;
  height: auto;
  background-color: #f4f4f4; /* Grey background */
  padding: 20px 0; /* Add padding to make space for the bullets */
  position: relative; /* Ensure everything is contained */
  border-radius: 10px; /* Optional: rounded corners */
}

.swiperSlide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 40px;
  background-color: #f4f4f4;
  border-radius: 10px;
}

.swiper-pagination {
  bottom: 10px !important; /* Position pagination inside the container */
  position: absolute; /* Ensure it stays relative to the Swiper container */
  width: 100%; /* Make the pagination span the full width of the container */
  display: flex;
  justify-content: center; /* Center the pagination bullets */
}

.swiper-pagination-bullet {
  background: #007bff;
  opacity: 0.6;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
}

.swiper-pagination-bullet-active {
  background: #1f0699;
  opacity: 1;
  transform: scale(1.2);
}

.swiper-button-next,
.swiper-button-prev {
  color: #007bff;
  top: 50%;
  transform: translateY(-50%);
}
