/* Container for the entire component */
.container {
  width: 100%;
  min-height: 60vh; /* Full height of the viewport */
}

/* Styling for the heading */
.heading {
  text-align: center;
  color: rgb(32, 31, 31);
  margin-bottom: 5px;
  font-size: 2.5rem;
}

/* Initial state for paragraphs (hidden) */
.hidden {
  opacity: 0.5;
  
  transition: opacity 1s ease-out;
}

/* Visible state for paragraphs (when scrolled into view) */
.visible {
  opacity: 1;
  transform: translateX(0);
}

/* Styling for the first paragraph (white background) */
.firstParagraph {
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  background-color: white;
  color: rgb(31, 29, 29);
  padding: 20px;
  height: 100px; /* Adjust this to match the desired height */
  margin-bottom: 20px; /* Add some space below */
  text-align: center; /* Ensure text is centered */
}
/* Styling for the second paragraph (background image) */
.secondParagraph {
  position: relative;
  height: 300px; /* Set the height for the section */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: rgb(254, 250, 250);
  overflow: hidden;
}

/* Background image with dull effect using ::before pseudo-element */
.secondParagraph::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../../assets/whoWeAre.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* Make the background image fixed */
  filter: brightness(40%); /* Make background image dull */
  z-index: 0; /* Ensure the image stays below the text */
}

/* Centered text content with bright text */
.secondParagraph .content-wrapper {
  text-align: center;
  z-index: 1; /* Ensures the text appears over the background image */
  opacity: 0;
  transform: translateX(10%);
  transition: opacity 1s ease-out, transform 1s ease-out;
  filter: brightness(150%); /* Brighten the text only */
}

/* Once visible, animate the text */
.visible .content-wrapper {
  opacity: 1;
  transform: translateY(0);
}

/* Styling for paragraphs within blockquotes */
.paragraph {
  line-height: 1.3;
  letter-spacing: 0.2px;
  z-index: 2;
}

/* Delay for the second paragraph's appearance */
.delay {
  transition-delay: 0.5s;
}

/* Media query for small screens (mobile devices) */
@media (max-width: 768px) {
  .container {
    height: auto;
    padding-top: 10px;
  }

  .heading {
    font-size: 1.5rem;
  }

  .paragraph {
    font-size: 16px;
  }
}
