/* HomePage container */
.homePage {
  position: relative;
  height: 98vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding-left: 20px;
}

/* Pseudo-element for background blur and lightening */
.homePage::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/newHomePagePic.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(1px);
  z-index: -1;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/* Custom layout for slide with image */
.slideWithImage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.slideImage {
  width: 50%;
  max-height: 60vh;
  margin-right: 20px;
}
.slideImage2 {
  width: 30%;
  max-height: 50vh;
  margin-right: 20px;
}

.slideContent {
  color: #191717;
  text-align: center;
  width: 50%;
  margin: 0 auto;
}

.headingStyles {
  font-size: 55px;
  font-weight: bold;
}

.paraStyles {
  font-size: 24px;
  color: #4a4949;
  font-weight: 400;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.customBullet {
  display: inline-block;
  background-color: rgb(193, 190, 190);
  border-radius: 5px;
  height: 6px;
  width: 100px;
  margin-right: 10px;
  margin-bottom: 50px;
}

.customBulletActive {
  background: linear-gradient(90deg, rgb(193, 189, 189) 10%, #0477f1 100%);
  background-size: 200%;
  animation: fillProgressBar 3s linear forwards;
}

@keyframes fillProgressBar {
  from {
    background-position: 200%;
  }
  to {
    background-position: 100%;
  }
}

.swiper-pagination {
  display: block !important;
  z-index: 10;
  position: relative;
}

/* Responsive Styles */

/* Tablet View */
@media (max-width: 768px) {
  .headingStyles {
    padding-top: 20px;
    font-size: 40px;
    font-weight: bold;
  }

  .paraStyles {
    font-size: 22px;
    color: #4a4949;
    font-weight: 400;
  }

  .customBullet {
    width: 80px;
    height: 6px;
    margin-bottom: 20px;
  }

  .slideWithImage {
    flex-direction: column;
    justify-content: center;
  }

  .slideImage {
    width: 80%;
    margin-bottom: 20px;
  }
  .slideImage2 {
    width: 70%;
    max-height: 50vh;
    margin-bottom: 20px;
  }
}

/* Mobile View */
@media (max-width: 480px) {
  .headingStyles {
    padding-top: 20px;
    font-size: 32px;
    font-weight: bold;
  }

  .paraStyles {
    font-size: 18px;
    color: #4a4949;
    font-weight: 400;
  }

  .customBullet {
    width: 60px;
    height: 5px;
    margin-bottom: 20px;
  }

  .slideImage {
    width: 100%;
    max-height: 50vh;
    margin-bottom: 20px;
  }
  
  .slideImage2 {
    width: 80%;
    max-height: 50vh;
    margin-bottom: 20px;
  }
  
  .slideContent {
    font-size: 20px;
    width: 90%;
  }
}
