.navbar {
  position: sticky; /* Make the navbar fixed */
  width: 100%; /* Span full width */
  left: 30%; /* Center horizontally */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 48px;
 
  backdrop-filter: blur(10px); /* Apply blur effect */
  color: black;
  z-index: 1000; /* Ensure the navbar is above other elements */
  
}

.navbarBrand {
  width: 80px; /* Adjusted width */
  height: 40px; /* Adjusted height */
}

.responsiveText {
  font-size: 2.5vw; /* Adjust the size relative to viewport width */
}

/* Media query to fine-tune the font size on smaller devices */
@media (max-width: 768px) {
  .responsiveText {
    font-size: 3.5vw; /* Make the text larger on smaller screens */
  }
}

/* Media query for larger screens */
@media (min-width: 1200px) {
  .responsiveText {
    font-size: 1.5rem; /* Set a fixed size for larger screens */
  }
}

.menuIcon {
  display: none;
  font-size: 30px;
  cursor: pointer;
  color: black;
}

.navLinks {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  margin-right: 60px; /* Add margin to create space on the right side */
}

.navLinks li {
  margin-left: 50px;
  cursor: pointer;
}

.navLink {
  text-decoration: none;
  color: black;
  font-size: 16px;
  font-weight: bold;
  transition: color 0.3s;
}

.navLink:hover {
  color: #ddd;
}

.dropdown {
  position: relative;
}

.dropdownMenu {
  display: none;
  position: absolute;
  background: linear-gradient(to bottom, #1d345c, #112448);
  min-width: 300px;
  z-index: 1;
  top: 100%;
  left: 0;
  list-style: none; /* Remove dots from the dropdown menu */
  padding: 0;
  margin: 0;
}

.dropdown.open .dropdownMenu {
  display: block;
}

.dropdownItem {
  padding: 0;
}

.flagIcon {
  margin-right: 8px; 
  width: 24px; 
  height: auto; 
}

.dropdownItem .navLink {
  display: flex;
  align-items: center;
  padding: 0.8rem 1rem;
}

.chevronIcon {
  margin-left: 8px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .menuIcon {
    display: block;
    margin-right: 40px;
  }

  .navLinks {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 30px;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8); /* Dark background for mobile menu */
  }

  .navLinks.open {
    display: flex;
  }

  .navLinks li {
    margin: 0;
    padding: 10px;
    text-align: center;
  }

  .navLink {
    font-size: 20px;
  }

  .dropdownMenu {
    position: static;
    width: 100%;
    box-shadow: none;
  }

  .dropdownItem {
    width: 100%;
  }

  .dropdownItem .navLink {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .navbarBrand {
    font-size: 20px;
  }

  .navLink {
    font-size: 18px;
  }
}
