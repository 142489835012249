/* Fade-in from left for text */
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Fade-in from right for social icons */
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* General fade-in for sections */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeInLeft {
  animation: fadeInLeft 2s ease-out forwards;
}

.fadeInRight {
  animation: fadeInRight 2s ease-out forwards;
}

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  background: linear-gradient(to top, #1d345c, #112448);
  color: #fff; /* Text color */
  padding: 20px;
  box-sizing: border-box;
  font-family: 'Times New Roman';
  
}

.imageSection {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 100px;
}

.contactSection {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.socialMedia {
  display: flex;
  gap: 20px;
}

.image {
  width: 100%;
  max-width: 500px; /* Adjust based on your design */
  height: auto;
  max-height: 400px;
  border-radius: 8px; /* Optional: adds rounded corners to the image */
}


.heading {
  margin-bottom: 20px;
  font-size: 2rem;
  animation: fadeInLeft 2s ease-out;
}

.contactInfo {
  display: flex;
  flex-direction: column;
  animation: fadeInLeft 2s ease-out;
}

.icon {
  font-size: 18px;
  color: #333;
}

.text {
  font-size: 18px;
  margin-left: 10px;
  letter-spacing: 0.5px;
  animation: fadeInLeft 2s ease-out;
}

.socialIcon {
  font-size: 24px;
  transition: opacity 0.3s;
  text-decoration: none; /* Removes underline */
  animation: fadeInRight 1.6s ease-out; /* Icons fade-in from right */
}

.socialIcon:hover {
  opacity: 0.7;
}

.instagram {
  color: #E1306C;
}

.facebook {
  color: #1877F2;
}

.twitter {
  color: #1DA1F2;
}

.linkedin {
  color: #0077B5;
}

.underline {
  text-decoration: underline;
  text-decoration-color: #f6f4f3; /* Optional: Customize the color */
  text-decoration-thickness: 1px; /* Optional: Customize the thickness */
  text-underline-offset: 4px; /* Optional: Adjust the offset */
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .container {
    flex-direction: row; /* Ensure side-by-side layout on larger tablets */
  }

  .heading {
    font-size: 1.75rem; /* Adjust font size for medium screens */
  }

  .contactInfo {
    margin-bottom: 15px;
  }

  .text {
    font-size: 17px; /* Slightly smaller text for medium screens */
    animation: fadeInLeft 2s ease-out;
  }
}

@media (max-width: 800px) {
  .container {
    flex-direction: column; /* Stack side-by-side items vertically on smaller screens */
  }

  .imageSection {
    padding-right: 0; /* Remove padding on small screens */
    margin-bottom: 20px; /* Add space below image on small screens */
  }

  .image {
    max-height: 250px; /* Adjust height for medium screens */
  }

  .heading {
    font-size: 1.5rem; /* Smaller font size on smaller screens */
  }

  .text {
    font-size: 16px; /* Adjust font size for smaller screens */
  }

  .socialMedia {
    gap: 15px; /* Reduced gap between icons for smaller screens */
  }
}

@media (max-width: 500px) {
  .image {
    max-height: 200px; /* Further reduce height for small screens */
  }

  .heading {
    font-size: 1.25rem; /* Smaller font size for small screens */
  }

  .text {
    font-size: 14px; /* Smaller font size for small screens */
  }

  .socialMedia {
    gap: 10px; /* Further reduced gap between icons */
  }

  .socialIcon {
    font-size: 20px; /* Smaller icon size for small screens */
  }
}

.addressContainer {
  display: flex;
  justify-content: space-between;
  gap: 2px;
}

@media (max-width: 800px) {
  .addressContainer {
    flex-direction: column;
  }
}

.infoItem {
  display: flex;
  align-items: center;
  margin: 5px 0;
  flex: 1; /* Ensure the items can grow and shrink */
}
